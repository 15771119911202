
/**
 * @name: 销售管理-退货单审核
 * @author:lili
 * @date: 2023-08-04 17:53
 * @description： 销售管理-退货单审核
 * @update: 2023-08-04 17:53
 */
import {Component, Vue} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {
  refundOrderAuditApi,
  refundOrderDetailApi,
  refundOrderExportApi,
  refundOrderQueryApi
} from "@/apis/sale/returnOrder";
import {IReturnOrder, IReturnOrderAudit, IReturnOrderParams} from "@/apis/sale/returnOrder/types";
import {deepCopy, exportFile} from "@/utils/common";
import Big from "big.js";

@Component({})
export default class saleReturnOrder extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IReturnOrderParams = {
    page: 1,
    limit: 10,
    payWay: [],
    gateIds: [],
    status: []
  }
  pageData: any = {
    orderPrice: '',
    payPrice: ''
  }
  isAudit: boolean = false
  // 表单参数
  modelForm: Partial<IReturnOrder> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: true,
    menu: true,
    labelWidth: '160px',
    viewTitle: '交易明细',
    column: [
      {
        label: '交易流水ID',
        prop: 'id',
        align: 'left',
        search: true,
        width: 180
      },
      {
        label: '销售订单编号',
        prop: 'saleOrderSn',
        align: 'left',
        width: 180
      },

      {
        label: "档口名称",
        prop: "gateIds",
        align: "center",
        search: true,
        searchMultiple: true,
        dicUrl: "/api/admin/gate/getGateList",
        dicFormatter: (res: any) => ({list: res.data, label: 'gateName', value: 'id'}),
        type: 'select',
        hide: true,
        viewHide: true
      },
      {
        label: "档口名称",
        prop: "gateName",
        align: "center",
        span: 12
      },
      {
        label: "订单金额",
        prop: "orderPrice",
        align: "center",
        span: 12,
        viewHide: true
      },
      {
        label: "支付金额",
        prop: "payPrice",
        align: "center",
        span: 12
      },
      {
        label: "抹零金额",
        prop: "wipeZeroPrice",
        align: "center",
        span: 12,
        viewHide: true
      },
      {
        label: "退货金额",
        prop: "refundPrice",
        align: "center",
        span: 12,
        viewHide: true
      },
      {
        label: "交易方式",
        prop: "payWay",
        align: 'center',
        searchMultiple: true,
        search: true,
        type: "select",  //1=现金 2=微信 3=支付宝 4=混合支付 5=财务结算
        dicData: [
          {label: '现金', value: 1},
          {label: '微信', value: 2},
          {label: '支付宝', value: 3},
          {label: '混合支付', value: 4},
          {label: '财务结算', value: 5},
          {
            label: "抖音",
            value: 6
          },
        ],
        span: 12,
      },

      {
        label: "申请人",
        prop: "refundByName",
        align: "center",
        //@ts-ignore
        default: '--',
        viewHide: true
      },

      {
        label: "审核状态",
        prop: "refundStatus",
        align: 'center',
        search: true,
        type: "select", //状态:1=待审核 2=已同意 3=已拒绝
        dicData: [
          {label: '待审核', value: 1},
          {label: '已同意', value: 2},
          {label: '已拒绝', value: 3},
        ],
        span: 12,
      },
      {
        label: "审核人",
        prop: "refundExamineByName",
        align: "center",
        //@ts-ignore
        default: '--',
        viewHide: true
      },

      {
        label: "交易时间",
        prop: "payTime",
        align: "center",
        type: "daterange",
        width: 180,
        //@ts-ignore
        default: '--',
        // @ts-ignore
        defaultTime: ["06:00:00", "05:59:59"],
        search: true,
        span: 12,
        viewHide: true
      },
      {
        label: "支付明细",
        prop: "orderPayStr",
        align: "center",
        hide: true,
      },
      {
        label: "挂账客户",
        prop: "hangingCustomer",
        align: "center",
        hide: true,
      },
    ]
  }

  //计算 明细 -商品金额合计
  get amountTotal() {
    let sum: number = 0;
    if (this.modelForm.saleOrderDtlVos && this.modelForm.saleOrderDtlVos.length > 0) {
      for (let i = 0; i < this.modelForm.saleOrderDtlVos.length; i++) {
        // sum = new Big(this.modelForm.saleOrderDtlVos[i]["price"] || 0).times(this.modelForm.saleOrderDtlVos[i]["qty"] || 0).plus(sum).toNumber();
        sum =new Big(sum).plus(this.modelForm.saleOrderDtlVos[i]["productPrice"] || 0).toNumber()
      }
    }
    return sum.toFixed(2)
  }

  /**
   * 详情
   * @param row
   */
  openDetail(row: IReturnOrder, index: number) {
    refundOrderDetailApi(row.id).then(e => {
      if (e) {
        // @ts-ignore
        e.orderPayStr = '';
        if (e.orderPayVos) {
          // @ts-ignore
          e.orderPayStr = '';
          e.orderPayVos.forEach(item => {
            // 类型:1=微信 2=支付宝 3=现金
            // @ts-ignore
            e.orderPayStr += `${item.types == 1 ? '微信' : item.types == 2 ? '支付宝' : '现金'} ${item.payPrice}    `
          })
        } else {
          // @ts-ignore
          e.orderPayStr = '--';
        }
        // @ts-ignore
        e.hangingCustomer = '--';
        if (e.hangingCustomerName && e.hangingCustomerPhone) {
          // @ts-ignore
          e.hangingCustomer = `${e.hangingCustomerName} ${e.hangingCustomerPhone}`
        }
        if (!this.isAudit) {
          // @ts-ignore
          this.$refs.crudRef.rowView(e, index)
        }
        this.modelForm = e;
      }
    })
  }

  /**
   * 审核
   * @param row
   */
  handleAudit(row: IReturnOrder, index: number) {
    this.isAudit = true;
    this.openDetail(row, index)

  }

  /**
   * 审核提交
   * @param types //类型：1=拒绝 2=同意
   */
  handleAffirm(types: number) {
    let obj: IReturnOrderAudit = {
      orderId: this.modelForm.id || '',
      types: types
    }
    refundOrderAuditApi(obj).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.isAudit = false;
        this.getList();
      }
    })
  }

  /**
   * 导出excel
   */
  exportExcel() {
    const form: IReturnOrderParams = deepCopy(this.queryParam);
    if (this.queryParam.payTime && this.queryParam.payTime.length) {
      form.payStartTime = this.queryParam.payTime[0]
      form.payEndTime = this.queryParam.payTime[1]
    } else {
      form.payStartTime = ""
      form.payEndTime = ""
    }
    if (this.queryParam.time && this.queryParam.time.length) {
      form.startTime = this.queryParam.time[0]
      form.endTime = this.queryParam.time[1]
    } else {
      form.startTime = ""
      form.endTime = ""
    }
    delete form.time
    refundOrderExportApi(form).then(e => {
      exportFile(e, '退货单.xlsx')
    })
  }

  getList() {
    const form: IReturnOrderParams = deepCopy(this.queryParam);
    if (this.queryParam.payTime && this.queryParam.payTime.length) {
      form.payStartTime = this.queryParam.payTime[0]
      form.payEndTime = this.queryParam.payTime[1]
    } else {
      form.payStartTime = ""
      form.payEndTime = ""
    }
    delete form.payTime
    if (this.queryParam.time && this.queryParam.time.length) {
      form.startTime = this.queryParam.time[0]
      form.endTime = this.queryParam.time[1]
    } else {
      form.startTime = ""
      form.endTime = ""
    }
    delete form.time
    refundOrderQueryApi(form).then(e => {
      this.pageData.orderPrice = e.orderPrice || 0;
      this.pageData.payPrice = e.payPrice || 0;
      let {commonPage} = e;
      //@ts-ignore
      this.tableData = commonPage.list;
      this.tableTotal = commonPage.total
    })
  }

  reset() {
    this.getList()
  }

  created() {
    this.reset()
  }
}
