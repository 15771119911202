/**
 * @name: 销售管理-退货单审核接口文件
 * @author: lili
 * @date: 2023-08-04 17:46
 * @description： 销售管理-退货单审核接口文件
 * @update: 2023-08-04 17:46
 */

import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {IReturnOrder, IReturnOrderAudit, IReturnOrderParams} from "@/apis/sale/returnOrder/types";

/**
 * 查询退货单列表
 * @param params 查询参数
 * @returns
 */
export const refundOrderQueryApi = (params: IReturnOrderParams) => postJ(`/admin/sale/order/refund/query?page=${params.page}&limit=${params.limit}`, params)

/**
 * 查询退货单导出
 * @param params 查询参数
 * @returns
 */
export const refundOrderExportApi = (params: IReturnOrderParams) => postJ("/admin/sale/order/refund/export", params,"blob")
/**
 * 获取退货单详情
 * @param id
 * @returns
 */
export const refundOrderDetailApi = (id: string) => get<IReturnOrder>("/admin/sale/order/refund/detail/"+id)

/**
 * 审核退货单
 * @param data
 * @returns
 */
export const  refundOrderAuditApi = (data: IReturnOrderAudit) => get("/admin/sale/order/refund/auditing", data)
